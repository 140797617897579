<template>
    <nav class="selector"
         v-bind:style="{ 'top':  top, 'left': left }">
    </nav>
</template>

<script>
    export default {
        name: 'ProductCategorySelector',
        props: {
            target: {
                required: true
            }
        },
        data() {
            return {}
        },
        computed: {
            top() {
                if (this.target) {
                    return `${(this.target.offsetTop + this.target.offsetHeight - 7.5)}px`
                }
            },
            left() {
                if (this.target) {
                    return `${(this.target.offsetLeft + this.target.offsetWidth / 2 - 7.5)}px`
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .selector {
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 15px 7.5px;
        border-color: transparent transparent rgba(110, 107, 106, 1) transparent;
        transition: left 300ms ease-in-out, top 300ms ease-in-out;
    }
</style>

<template>
    <section class="products">
        <tool-bar has-search
                  v-bind:lang.sync="lang">
            Products
            <template slot="buttons">
                <div class="level-item">
                    <button class="button has-text-black"
                            v-on:click="isModalReorderOpen = true">
                        <span class="icon is-small">
                            <font-awesome-icon v-bind:icon="['far', 'arrows']"></font-awesome-icon>
                        </span>
                        <span>Reorder</span>
                    </button>
                </div>
                <div class="level-item">
                    <router-link class="button has-text-black"
                                 v-bind:to="{ name: 'add-product' }">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add a Product</span>
                    </router-link>
                </div>
            </template>
        </tool-bar>

        <div class="content mb-5">
            <product-category-filter v-bind:lang="lang"
                                     v-bind:categories="categories"
                                     v-bind:series="series"
                                     v-model="selectedFilter">
            </product-category-filter>

            <div class="product-wrapper row">
                <product-card v-for="product in filterProducts"
                              v-bind:key="product._id"
                              v-bind:item="product"
                              v-bind:lang="lang">
                </product-card>
            </div>
        </div>

        <modal-reorder-products v-model="isModalReorderOpen"></modal-reorder-products>
    </section>
</template>

<script>
import draggable from "vuedraggable";

import ProductCategoryFilter from "./ProductCategoryFilter"
import ProductCard from "./Products/ProductCard"
import ToolBar from "../layouts/ToolBar";
import ModalReorderProducts from "./Products/ModalReorderProducts";

export default {
    name: "Products",
    components: {
        ModalReorderProducts,
        draggable,
        ToolBar,
        ProductCard,
        ProductCategoryFilter
    },
    data() {
        return {
            isLoading: false,
            isEdited: false,
            lang: "en",
            isModalReorderOpen: false,

            selectedFilter: null,
        };
    },
    computed: {
        categories () {
            return this.$store.getters["shop/categories"];
        },
        series () {
            return this.$store.getters["shop/series"];
        },
        products () {
            return this.$store.getters["shop/products"];
        },
        filterProducts: {
            get () {
                if (this.selectedFilter) {
                    if (this.selectedFilter.type === "type") {
                        return this.products
                            .filter(
                                ({ category }) => category === this.selectedFilter._id
                            );
                    }
                    return this.products
                        .filter(
                            ({ series }) => series === this.selectedFilter._id
                        );
                }
                return this.products;
            },
            set (val) {

            }
        }
    },
    methods: {
        save () {

        },

        onDragEnd () {
            this.isEdited = true;
        },
    }
}
</script>

<style lang="scss" scoped>
.products {
    .button.has-text-black {
        text-decoration: none;
    }

    & > .content {
        $theme-colors: (
            "dark": rgba(113, 113, 113, 1)
        );
        @import "~bootstrap/scss/bootstrap";

        background-color: rgba(255, 255, 255, 1);
        margin: 1rem;
        padding: 1rem;
    }

    .product-enter-active,
    .product-leave-active {
        transition: opacity 250ms;
    }

    .product-enter,
    .product-leave-to {
        opacity: 0;
    }
}
</style>

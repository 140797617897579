<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Delete a Product</p>
        </header>
        <section class="modal-card-body">
            <article class="message is-dark">
                <div class="message-body">
                    This action is <strong>irreversible</strong>. Are you sure you want to delete this product?
                </div>
            </article>
            <div class="wrapper d-flex justify-content-center align-items-center">
                <div class="card product position-relative">
                    <img class="image d-block mx-auto"
                         v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ product.images[0] }`">
                    <div>
                        <h4 class="title text-center"
                            v-html="product.title[lang]"></h4>
                        <h5 class="subtitle text-center"
                            v-html="product.subtitle[lang]"></h5>
                        <p class="text-center">HK${{ product.prices[0].hkd }}</p>
                    </div>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button"
                    v-on:click="$parent.close()">
                Cancel
            </button>
            <button class="button is-danger"
                    v-bind:class="{ 'is-loading': loading }"
                    v-on:click="deleteProduct">
                Delete
            </button>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'ModalProductDelete',
        props: {
            product: {
                type: Object,
                require: true
            }
        },
        data() {
            return {
                mapOptions: {
                    disableDefaultUI: true
                },
                loading: false,

                locationTypeMap: {
                    'salon': {
                        'zh-hk': 'Marier美容中心',
                        'zh-cn': 'Marier美容中心',
                        'en': 'Marier Salon'
                    },
                    'counter': {
                        'zh-hk': 'Marier專門店/專櫃',
                        'zh-cn': 'Marier专门店/专柜',
                        'en': 'Marier Counter'
                    }
                },

                lang: 'en'
            }
        },
        methods: {
            async deleteProduct() {
                this.loading = true
                try {
                    await this.$store.dispatch('shop/deleteProduct', this.product._id)
                } catch (e) {
                    this.loading = false
                    return
                }
                this.loading = false
                this.$parent.close()
            }
        }
    }
</script>

<style lang="scss" scoped>
    $theme-colors: (
        "dark": rgba(113, 113, 113, 1)
    );
    @import "~bootstrap/scss/bootstrap";

    .modal-card-body {
        padding-left: 0;
        padding-right: 0;
    }

    .modal-card-foot {
        display: flex;
        justify-content: flex-end;
    }

    .message {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .wrapper {
        padding: 1rem;
        background-color: rgba(245, 245, 245, 1);

        & > .product {
            width: 320px;
            background-color: rgba(255, 255, 255, 1);
            padding: 1rem;

            .image {
                max-height: 300px;
            }
        }
    }
</style>

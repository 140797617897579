<template>
    <b-modal has-modal-card
             v-bind:active.sync="isModalOpen">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Reorder products
                </p>
            </header>
            <section class="modal-card-body">
                <article class="message">
                    <div class="message-body">
                        You can reorder products here. The order will be reflected in the main web pages.
                    </div>
                </article>

                <div class="tabs is-small is-toggle is-toggle-rounded">
                    <ul>
                        <li v-bind:class="{ 'is-active': lang === 'en' }"
                            v-on:click="lang = 'en'">
                            <a>English</a>
                        </li>
                        <li v-bind:class="{ 'is-active': lang === 'zh-hk' }"
                            v-on:click="lang = 'zh-hk'">
                            <a>繁體中文</a>
                        </li>
                        <li v-bind:class="{ 'is-active': lang === 'zh-cn' }"
                            v-on:click="lang = 'zh-cn'">
                            <a>簡體中文</a>
                        </li>
                    </ul>
                </div>

                <nav class="panel">
                    <div class="panel-heading">
                        <div class="thumbnail">
                            <strong></strong>
                        </div>
                        <div class="name">
                            <strong>Name</strong>
                        </div>
                        <div class="control"></div>
                    </div>
                    <draggable v-model="products"
                               v-on:end="onDragEnd">
                        <transition-group>
                            <a class="panel-block is-active"
                               v-for="(product, index) in products"
                               v-bind:key="product._id">
                                <div class="thumbnail">
                                    <img class=""
                                         v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ product.images[0] }`">
                                </div>
                                <div class="name">
                                    <strong v-html="product.title[lang]"></strong>
                                </div>
                                <div class="control">
                                    <div class="field has-addons">
                                        <p class="control">
                                            <button class="button"
                                                    v-on:click="moveUp(index)">
                                                <span class="icon is-small">
                                                    <font-awesome-icon v-bind:icon="['fad', 'sort-up']"></font-awesome-icon>
                                                </span>
                                            </button>
                                        </p>
                                        <p class="control">
                                            <button class="button"
                                                    v-on:click="moveDown(index)">
                                                <span class="icon is-small">
                                                    <font-awesome-icon v-bind:icon="['fad', 'sort-down']"></font-awesome-icon>
                                                </span>
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </transition-group>
                    </draggable>
                </nav>
            </section>
            <footer class="modal-card-foot">
                <button class="button" type="button"
                        v-on:click="closeModal">
                    Cancel
                </button>
                <button class="button is-primary"
                        v-bind:disabled="!isEdited"
                        v-bind:class="{ 'is-loading': isLoading }"
                        v-on:click="save">
                    Save
                </button>
            </footer>
        </div>
    </b-modal>
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: "ModalReorderProducts",
    components: {
        draggable,
    },
    props: {
        "value": {
            type: Boolean,
        },
    },
    data () {
        return {
            lang: "en",
            isLoading: false,
            isEdited: false,

            products: [],
        };
    },
    computed: {
        productsInStore () {
            return this.$store.getters["shop/products"];
        },
        isModalOpen: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit("input", val);
            }
        },
    },
    methods: {
        onDragEnd () {
            this.isEdited = true;
        },

        moveUp (currentIndex) {
            if (currentIndex > 0) {
                const temp = this.products[currentIndex - 1];
                this.$set(this.products, currentIndex - 1, this.products[currentIndex]);
                this.$set(this.products, currentIndex, temp);
                this.isEdited = true;
            }
        },

        moveDown (currentIndex) {
            if (currentIndex < this.products.length - 1) {
                const temp = this.products[currentIndex + 1];
                this.$set(this.products, currentIndex + 1, this.products[currentIndex]);
                this.$set(this.products, currentIndex, temp);
                this.isEdited = true;
            }
        },

        async save () {
            if (this.isEdited) {
                this.isLoading = true;
                try {
                    await this.$store.dispatch(
                        "shop/updateShop",
                        {
                            "products": this.products
                                .map(
                                    ({ _id }) => _id
                                ),
                        }
                    );
                } catch (e) {
                    console.error(e);
                    this.isLoading = false;
                    return;
                }
                this.isLoading = false;
                this.isModalOpen = false;
            }
        },

        closeModal () {
            this.products = this.productsInStore.slice(0);
            this.isModalOpen = false;
        },
    },
    mounted () {
        this.products = this.productsInStore.slice(0);
    },
    watch: {
        productsInStore (newVal) {
            this.products = newVal.slice(0);
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-card-foot {
    justify-content: flex-end;
}

.tabs {
    & > ul {
        display: flex;
        justify-content: center;
    }
}

.panel-heading {
    font-size: 16px;
}

.panel-heading,
.panel-block {
    display: flex;
    justify-content: stretch;

    & > .thumbnail {
        flex: 1;
        margin-right: 5px;

        & > img {
            width: 75px;
            height: 75px;
        }
    }

    & > .name {
        flex: 3;
        margin-right: 5px;
    }

    & > .control {
        flex: 1;
    }
}
</style>

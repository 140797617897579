<template>
    <div class="product-category-filter">
        <div class="categories d-flex flex-row justify-content-around align-items-center">
            <product-category-selector v-if="selectedCategoryElement"
                                       v-bind:target="selectedCategoryElement">
            </product-category-selector>
            <div class="category text-center"
                 ref="categoryElements"
                 v-for="category in internalCategories"
                 v-bind:key="category._id"
                 v-on:click="selectCategory(category, $event)">
                {{ category.name[lang] }}
            </div>
        </div>
        <div class="categories d-flex flex-row flex-wrap justify-content-around align-items-center mb-3">
            <product-category-selector v-if="selectedSubCategoryElement"
                                       v-bind:target="selectedSubCategoryElement">
            </product-category-selector>
            <div class="category text-center"
                 ref="subCategoryElements"
                 v-for="subCategory in subCategories"
                 v-bind:key="subCategory._id"
                 v-on:click="selectSubCategory(subCategory, $event)">
                <span v-if="subCategory.name">
                    {{ subCategory.name[lang] }}
                </span>
                <span v-if="subCategory.title">
                    {{ subCategory.title[lang] }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import ProductCategorySelector from "./ProductCategorySelector";

export default {
    name: "ProductCategoryFilter",
    components: {
        ProductCategorySelector
    },
    props: {
        lang: {
            type: String,
            required: true
        },
        categories: {
            type: Array,
            required: true
        },
        series: {
            type: Array,
            required: true
        },
        value: {
            type: String,
        },
    },
    data() {
        return {
            selectedCategoryElement: null,
            selectedSubCategoryElement: null,

            internalCategories: [{
                _id: "series",
                name: {
                    "en": "Series",
                    "zh-hk": "系列",
                    "zh-cn": "系列"
                }
            }, {
                _id: "type",
                name: {
                    "en": "Type",
                    "zh-hk": "類型",
                    "zh-cn": "类型"
                }
            }, {
                _id: "all",
                name: {
                    "en": "All",
                    "zh-hk": "全部產品",
                    "zh-cn": "全部产品"
                }
            }],

            subCategories: []
        };
    },
    computed: {
        selectedCategory() {
            return this.internalCategories[this.internalCategories.length - 1]
        }
    },
    methods: {
        selectCategory(
            { _id },
            { currentTarget }
        ) {
            switch (_id) {
                case "series": {
                    this.subCategories = this.series;
                    break;
                }

                case "type": {
                    this.subCategories = this.categories;
                    break;
                }

                case "all": {
                    this.subCategories = [];
                    this.$emit("input", null);
                    break;
                }
            }

            this.selectedCategoryElement = currentTarget
            this.selectedSubCategoryElement = null
        },

        selectSubCategory(
            subCategory,
            { currentTarget }
        ) {
            this.$emit("input", subCategory);
            this.selectedSubCategoryElement = currentTarget
        }
    },
}
</script>

<style lang="scss" scoped>
$theme-colors: (
    "dark": rgba(113, 113, 113, 1)
);
@import "~bootstrap/scss/bootstrap";

.product-category-filter {
    & > .categories {
        position: relative;

        font-size: 18px;
        font-weight: normal;

        min-height: 65.5px;
        padding-bottom: 17.5px;

        &:first-child {
            border-bottom: solid 1px rgba(220, 220, 220, 1);
        }

        & > .category {
            flex: 1;

            white-space: nowrap;
            user-select: none;
            cursor: pointer;

            padding: 10px;

            &:hover {
                font-weight: bold;
            }
        }
    }
}
</style>

<template>
    <div class="product-card col-md-4 col-lg-3 mb-4">
        <article class="product position-relative h-100">
            <img class="image d-block mx-auto"
                 v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ item.images[0] }`">

            <div class="mb-3">
                <h4 class="text-center font-weight-bolder"
                    v-html="item.title[lang]"></h4>
                <h5 class="text-center font-weight-normal"
                    v-html="item.subtitle[lang]"></h5>
            </div>

            <div class="button-group field is-justify-content-center is-grouped">
                <p class="control mb-0">
                    <router-link class="button is-dark"
                                 v-bind:to="{ name: 'edit-product', params: { productId: item._id } }">
                <span class="icon">
                    <font-awesome-icon icon="cog"></font-awesome-icon>
                </span>
                    </router-link>
                </p>
                <p class="control mb-0">
                    <button class="button is-danger" style="border-radius: 4px;"
                            v-bind:class="{ 'is-loading': loading }"
                            v-on:click="isDeleteProductModalActive = true">
                <span class="icon">
                    <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                </span>
                    </button>
                </p>
            </div>

            <b-modal has-modal-card
                     v-bind:active.sync="isDeleteProductModalActive">
                <modal-product-delete
                    v-bind:product="item">
                </modal-product-delete>
            </b-modal>
        </article>
    </div>
</template>

<script>
import ModalProductDelete from "../../modals/ModalProductDelete";

export default {
    name: "Product",
    components: {
        ModalProductDelete
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        lang: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            loading: false,
            isDeleteProductModalActive: false
        }
    },
    computed: {

    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
$theme-colors: (
    "dark": rgba(113, 113, 113, 1)
);
@import "../../../../node_modules/bootstrap/scss/bootstrap";

.product {
    padding-bottom: 40px;

    .image {
        height: 300px;
        max-width: 100%;
        width: auto;
        object-fit: contain;
    }

    .title {

    }

    .subtitle {

    }

    & > .button-group {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
    }
}
</style>
